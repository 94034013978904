import NextImage from 'next/image'
import styled from 'styled-components'

import { Tag } from 'components/ui'
import { IllustratedIcon } from 'components/ui/IllustratedIcon'
import { Container } from 'components/ui/deprecated/Layout'
import { Text } from 'components/ui/deprecated/Text'
import { UspStoryblok } from 'lib/storyblok/types'
import { getStoryblokImageAttributes } from 'lib/utils/content'

type UspProps = {
  block: UspStoryblok
}

export const Usp = ({ block, ...props }: UspProps) => {
  return (
    <Color>
      <Container>
        <Wrapper {...props}>
          <InnerWrapper>
            {block.tag && <Tag>{block.tag}</Tag>}
            <Text as="h3" variant="title/large" className="mt-4">
              {block.title}
            </Text>

            {block.description && (
              <Text as="p" variant="twenty" className="mt-5">
                {block.description}
              </Text>
            )}
            <WrapperImgs>
              <GradientDiv />
              {block.image.filename && (
                <WrapperSquareImage>
                  <NextImage
                    fill
                    priority
                    src={getStoryblokImageAttributes(block.image).src}
                    alt={getStoryblokImageAttributes(block.image).alt}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </WrapperSquareImage>
              )}
            </WrapperImgs>
          </InnerWrapper>
          <Grid>
            {block.entry.map((entry) => {
              const illustratedIcon = entry.icon?.[0].icon
              const illustratedIconRectangleColor =
                entry.icon?.[0].rectangle_color
              return (
                <WrapperGrid key={entry._uid}>
                  {illustratedIcon && (
                    <div className="mr-5">
                      <IllustratedIcon
                        icon={illustratedIcon}
                        rectangleColor={illustratedIconRectangleColor}
                      />
                    </div>
                  )}
                  <div>
                    <StyledDescription as="p" variant="twenty/regular">
                      {entry.title}
                    </StyledDescription>

                    <Text as="p" variant="eighteen" className="mt-1">
                      {entry.description}
                    </Text>
                  </div>
                </WrapperGrid>
              )
            })}
          </Grid>
        </Wrapper>
      </Container>
    </Color>
  )
}

const Color = styled.div`
  background-color: ${({ theme }) => theme.colors.background.dark};
`

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 0px;
  flex-direction: column;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    padding: 5rem 0px;
  }
`

const InnerWrapper = styled.div`
  margin-right: 0rem;
  margin-bottom: 2.5rem;
  width: 100%;

  ${({ theme }) => theme.media.lg} {
    max-width: 30.625rem;
    margin-right: 5rem;
    margin-bottom: 0rem;
    width: 50%;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.25rem;
  width: 100%;

  ${({ theme }) => theme.media.lg} {
    gap: 2.5rem;
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
  }
`

const WrapperGrid = styled.div`
  display: flex;

  ${({ theme }) => theme.media.lg} {
    flex-direction: column;
  }
`

const WrapperSquareImage = styled.div`
  display: block;
  position: relative;
  width: 60%;
  height: 15rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;

  ${({ theme }) => theme.media.md} {
    width: 50%;
  }

  ${({ theme }) => theme.media.lg} {
    width: 18.25rem;
    height: 22rem;
  }
`

const GradientDiv = styled.div`
  width: 40%;
  height: 15rem;
  background: ${({ theme }) =>
    `linear-gradient(135deg, ${theme.colors.palette.hyacinth.default}, ${theme.colors.palette.gradient.lavender})`};
  border-radius: 1.25rem;

  ${({ theme }) => theme.media.md} {
    width: 50%;
  }

  ${({ theme }) => theme.media.lg} {
    width: 11.125rem;
    height: 22rem;
  }
`

const WrapperImgs = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 2.5rem;
  justify-content: space-between;

  ${({ theme }) => theme.media.md} {
    margin-top: 3.75rem;
  }
`

const StyledDescription = styled(Text)`
  ${({ theme }) => theme.media.lg} {
    margin-top: 0.75rem;
  }
`
